<template>
  <!-- Hero Section -->
  <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/quality/hero1.jpg')})`}">
    <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
      <h1 class="text-white text-6xl text-center ">QUALITY & ACCREDITATIONS</h1>
    </div>
  </section>

  <section class="container mx-auto text-center text-lg p-16">
    <h3 class=" mb-4">At Reid & Mitchell, we're committed to delivering the highest quality electrical
      engineering solutions, underscored by our steadfast dedication to continuous improvement and adherence to
      international standards. We're proud to say that we are ISO9001:2015 compliant, a testament to our relentless focus
      on quality management and customer satisfaction.</h3>

  </section>

  <!-- Industries Section -->
  <section class="  text-center">


    <div class="container mx-auto space-y-8 text-start p-16">
  

  <div class="p-6 bg-white  shadow-md space-y-4 ease-in duration-300">
    <h3 class="text-xl font-semibold ">Our Quality Commitment</h3>
    <p class="">Our objective is to achieve warranties and reworks below 1% of the direct cost of sales. This commitment to quality drives our strategy and operations, enabling us not only to improve our services but also to ensure the long-term sustainability of our divisions. We understand that by enhancing our in-house quality capabilities, we can add more value to our customers, fostering a culture of excellence that permeates every facet of our operations.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h3 class="text-xl font-semibold ">Comprehensive Quality Controls</h3>
    <p class="">To achieve our quality goals, we implement comprehensive quality controls that go above and beyond the requirements of the ISO9001:2015 standard. We continuously enhance our workshop's quality capabilities, ensuring that every product we deliver is crafted to the highest standards of performance and reliability.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h3 class="text-xl font-semibold ">Consistent Quality Standards</h3>
    <p class="">At Reid & Mitchell, we strive to avoid inconsistencies and ensure every project meets or exceeds our customers' expectations. To this end, we implement quality documents that align with ISO standards, our own rigorous R&M standards, and the unique requirements of our customers. These documents serve as a roadmap, guiding our processes and ensuring that every step we take is in service of delivering high-quality, reliable solutions.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h3 class="text-xl font-semibold ">Quality and Sustainability</h3>
    <p class="">Our focus on quality does not end with our products and services; it also extends to our commitment to sustainable business practices. By continuously improving our processes and reducing rework, we are able to decrease waste, conserve resources, and minimize our environmental impact. At Reid & Mitchell, quality and sustainability go hand in hand, helping us build a better future for our company, our customers, and our planet.</p>
  </div>

  
  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">DIFR Objective</h2>
    <p class="">At Reid & Mitchell, we prioritize the safety of our team above all else. We strive to ensure that our Disabling Injury Frequency Rate (DIFR) remains below 1.0 per 200,000 man-hours. By implementing rigorous safety protocols and regular training, we aim to create a work environment that minimizes the risk of injury and fosters the wellbeing of our team members.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">Risk Rating</h2>
    <p class="">Our commitment to risk management has enabled us to achieve a risk rating of above 96%. We achieve this by adhering to industry best practices and applying robust risk assessment methodologies in all our operations. Our high risk rating is a testament to our unwavering commitment to safety, reliability, and operational excellence.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">Environmental Status Objective</h2>
    <p class="">Our commitment to environmental sustainability is reflected in our objective to achieve an environmental status of above 98%. This means we continuously work to reduce our environmental impact through waste management, energy efficiency, and sustainable practices. We believe in the importance of protecting our planet and are dedicated to operating in an environmentally responsible manner.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">NOSA 5-star Grading for Health and Safety</h2>
    <p class="">Maintaining and improving our NOSA 5-star grading for health and safety is a critical objective for us at Reid & Mitchell. This grading is a testament to our commitment to creating a safe and healthy work environment. We continuously strive to uphold the highest standards of health and safety in our operations, ensuring the well-being of our team members and the communities we serve.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">Marsh Risk Grading</h2>
    <p class="">We're proud to have a high Marsh risk grading, an independent validation of our dedication to managing risk effectively. Marsh's risk grading is recognized as a gold standard in the industry, and achieving a high score in this grading signifies our commitment to risk management excellence.</p>
  </div>

  <div class="p-6 bg-white  shadow-md space-y-4">
    <h2 class="text-xl font-semibold ">Marsh Environmental Grading</h2>
    <p class="">Our high Marsh environmental grading reflects our dedication to environmental sustainability. Marsh's grading system provides a benchmark for environmental performance, and our high score is a testament to our efforts in environmental conservation and sustainability. We're committed to continuously improving our environmental performance and reducing our footprint.</p>
  </div>
  <div class="p-6 bg-white  shadow-md space-y-4">
    <p class="">Join us on our journey towards excellence. At Reid & Mitchell, quality isn't just a goal - it's a way of life.</p>
  </div>
</div>
<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 p-3">
    <div class="grid grid-cols-3 gap-4">
        <!-- Replace with your image source and alt text -->
        <div class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <img class="w-full h-auto grayscale hover:grayscale-0 p-1" src="@/assets/img/quality/iso.png" alt="Logo 1">
        </div>
        <div class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <img class="w-full h-auto grayscale hover:grayscale-0 p-1" src="@/assets/img/quality/nosa.png" alt="Logo 2">
        </div>
        <div class="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
            <img class="w-full h-auto grayscale hover:grayscale-0 p-1 " src="@/assets/img/quality/marsh.png " alt="Logo 3">
        </div>
        <!-- Repeat for more logos -->
    </div>
</div>


    <FullWidthSlider :images="sliderImages" />

  </section>
  

</template>

<script>

import FullWidthSlider from "@/components/FullWidthSlider";


import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Quality',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {

    FullWidthSlider
  },
  data() {
    return {

      sliderImages: [
        { src: require("@/assets/img/quality/qwide1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/quality/qwide2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/quality/qwide3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/quality/qwide4.jpg"), alt: "Page 1 Image 1" },
        // Add more images for page 1
      ]

    }
  }
}
</script>