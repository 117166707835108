import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles.css';
import router from './router';
import VueGtag from "vue-gtag";
import { createHead } from '@unhead/vue'
import { UnheadPlugin } from '@unhead/vue/vue2'

const head = createHead()

// Create your Vue app using createApp
const app = createApp(App);

// Use the router
app.use(router);

// Use the VueGtag plugin
app.use(VueGtag, {
  config: { 
    id: "G-Q11J6XL56H"
  }
});

// Use the UnheadPlugin
app.use(UnheadPlugin);

// Use the created head instance
app.use(head);

// Mount the app
app.mount('#app');
