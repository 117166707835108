<template>
    <div>
        <!-- Hero Section -->
        <section class="bg-cover bg-center screen50 "
            :style="{ backgroundImage: `url(${require('@/assets/img/training/traininghero.jpg')})` }">
            <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
                <h1 class="text-white text-6xl text-center ">TRAINING & DEVELOPMENT</h1>
            </div>
        </section>

        <!-- About Section -->
        <section class=" body-font">
            <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">

                <div
                    class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">OUR TRAINING
                        <br class="hidden lg:inline-block">SERVICE
                    </h1>
                    <p class="mb-8 leading-relaxed text-lg">With a set culture of continuous improvement and skills development,
                        Reid & Mitchell have, over the decades invested in maintaining a strong training and personnel
                        development philosophy.
                        Not only have R&M catered for internal training, but has offered this training to their valued
                        customers over the years.

                    </p>

                </div>
               
            </div>
        </section>

        <!-- COURSE Section -->
        <section class="  text-center xl:m-10">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium p-16">Courses and seminars offered to our
                customers include:

            </h1>

            <div class="container mx-auto px-4">
                <tabs v-model="activeTab" class="p-5 "> <!-- class appends to content DIV for all tabs -->
                    <tab name="first" title="DC Rotating Equipment Maintenance" class="">
                        <div class="p-6  mx-auto bg-white  shadow-md flex items-center space-x-4 text-start">

                            <div>
                                <h2 class="text-xl font-semibold ">DC Rotating Equipment Maintenance</h2>
                                <p class="mt-2 ">Immerse yourself in the world of Direct Current (DC) rotating
                                    equipment with our intensive 2-day course. Reid & Mitchell DC Rotating Equipment
                                    Maintenance
                                    course provides a comprehensive learning experience that brings together the essentials
                                    of
                                    DC machines, their maintenance, and key operational aspects. This course is designed for
                                    both beginners and experienced engineers who wish to deepen their knowledge and skills
                                    in
                                    the maintenance and operation of DC machines. With a blend of theoretical principles and
                                    practical examples, this course will equip you with the knowledge and skills to
                                    diagnose,
                                    prevent, and solve common problems in DC rotating equipment.</p>
                                <h3 class="mt-4 text-lg font-semibold">Course Curriculum:</h3>
                                <ol class="list-decimal list-inside mt-2 ">
                                    <li><strong>Basic Principles of Magnetic Induction Motors:</strong> <br>Begin your
                                        journey with
                                        a solid foundation, learning about the principles that underpin the operation of
                                        magnetic induction motors. Understand the fundamental concepts of electromagnetism
                                        and
                                        how it is applied in the context of these motors.</li>
                                    <li class="mt-2"><strong>Introduction to DC Rotating Maintenance:</strong> <br>Expand
                                        your
                                        understanding by learning about the specifics of maintaining DC rotating machines.
                                        This
                                        module will guide you through the maintenance procedures, schedules, and techniques
                                        that
                                        ensure the longevity and efficient operation of these machines.</li>
                                    <li class="mt-2"><strong>DC Rotating Machines:</strong><br> Delve deeper into the world
                                        of DC
                                        machines, exploring their various types, uses, and important components. In this
                                        module,
                                        you'll learn about the design, operation, and unique characteristics of different DC
                                        rotating machines.</li>
                                    <li class="mt-2"><strong>The ABC of Flashovers:</strong> <br>Flashovers can cause
                                        significant
                                        damage to electrical equipment. In this final module, you'll learn how to detect the
                                        signs of an impending flashover, understand the causes, and implement preventive
                                        measures. By understanding the 'ABCs' of flashovers, you will be able to manage and
                                        mitigate the risks associated with these potentially damaging events.</li>
                                </ol>
                            </div>
                        </div>




                    </tab>
                    <tab name="second" title="OHV (Off-Highway Vehicle) Wheelmotor Maintenance & Controls">
                        <div class="p-6  mx-auto bg-white  shadow-md flex items-center space-x-4 text-start">

                            <div>
                                <h2 class="text-xl font-semibold ">OHV (Off-Highway Vehicle) Wheelmotor
                                    Maintenance & Controls</h2>
                                <p class="mt-2 ">Dive into the heart of Off-Highway Vehicle (OHV) maintenance
                                    with our specialized course on Wheelmotor Maintenance & Controls. This course, offered
                                    by Reid & Mitchell, is tailored to provide a comprehensive understanding of the
                                    maintenance, controls, and problem identification related to OHV wheelmotors.</p>

                                <p class="mt-2 ">Ideal for engineers and technicians involved in the
                                    maintenance and operation of OHVs, this course will equip you with the practical skills
                                    and theoretical knowledge necessary to ensure optimal performance and longevity of
                                    wheelmotors. From understanding the intricacies of component breakdown to mastering
                                    advanced fault finding techniques in control systems, this course covers it all.</p>

                                <h3 class="mt-4 text-lg font-semibold">Course Curriculum:</h3>
                                <ol class="list-decimal list-inside mt-2 ">
                                    <li><strong>Wheelmotor Maintenance through Oil Analysis:</strong> <br>Learn the
                                        techniques of predictive maintenance using oil analysis, a key method for early
                                        detection of potential wheelmotor issues.</li>
                                    <li class="mt-2"><strong>Component Breakdown Wheelmotors:</strong> <br>Gain an in-depth
                                        understanding of the various components of wheelmotors, their functions, and common
                                        issues.</li>
                                    <li class="mt-2"><strong>Grease Modification:</strong> <br>Explore the types,
                                        properties, and correct usage of greases in wheelmotor maintenance and modification.
                                    </li>
                                    <li class="mt-2"><strong>Identification of Problems:</strong><br> Develop your
                                        troubleshooting skills and learn to identify common wheelmotor issues before they
                                        escalate.</li>
                                    <li class="mt-2"><strong>Procedure for Taking Oil Samples:</strong> <br>Master the
                                        correct procedures for collecting oil samples, a crucial step in oil analysis.</li>
                                    <li class="mt-2"><strong>Lubricants:</strong> <br>Understand the role of different
                                        lubricants, their properties, and their application in wheelmotor maintenance.</li>
                                    <li class="mt-2"><strong>Key Elements:</strong> <br>Study the key elements involved in
                                        wheelmotor maintenance and controls, providing a holistic understanding of OHV
                                        wheelmotor systems.</li>
                                    <li class="mt-2"><strong>Mechanical Inspection & Recordings (Backlash/End
                                            Play):</strong> <br>Learn how to perform mechanical inspections and record key
                                        parameters such as backlash and end play.</li>
                                    <li class="mt-2"><strong>Armature Tests:</strong><br> Get hands-on with practical
                                        armature testing techniques to assess and maintain the operational efficiency of
                                        wheelmotors.</li>
                                    <li class="mt-2"><strong>Illustration of Typical Failures:</strong> <br>Understand
                                        common failures in wheelmotor operation through illustrative examples, equipping you
                                        with knowledge to preempt and tackle such situations.</li>
                                    <li class="mt-2"><strong>The ABC of Flashovers:</strong> <br>Learn about flashovers,
                                        their causes, and prevention methods to protect your equipment from potential
                                        damage.</li>
                                    <li class="mt-2"><strong>Statex III Control System Fault Finding and
                                            Maintenance:</strong> <br>This final module will teach you how to troubleshoot
                                        and maintain the Statex III control system, a common system in OHVs, ensuring smooth
                                        and efficient operations.</li>
                                </ol>

                            </div>
                        </div>

                    </tab>
                    <tab name="third" title="Carbon Brush Maintenance Training">
                        <div class="p-6 mx-auto bg-white  shadow-md flex items-center space-x-4 text-start">

                            <div>
                                <h2 class="text-xl font-semibold ">Carbon Brush Maintenance Training</h2>
                                <p class="mt-2 ">Welcome to the world of carbon brush maintenance with Reid &
                                    Mitchell's specialized training course. This course is designed to equip you with a
                                    robust understanding of carbon brush theory, principles, and their maintenance.</p>

                                <p class="mt-2 ">Whether you are a seasoned professional or a beginner in the
                                    field of electrical engineering, our Carbon Brush Maintenance Training course is
                                    tailored to offer you the knowledge and best practices needed to ensure the optimal
                                    performance and longevity of carbon brushes in various applications.</p>

                                <p class="mt-2 ">Through a blend of theory and practical examples, this course
                                    will provide you with a comprehensive understanding of carbon brushes, their function,
                                    and the vital role they play in the performance of electrical machines.</p>

                                <h3 class="mt-4 text-lg font-semibold">Course Curriculum:</h3>
                                <ol class="list-decimal list-inside mt-2 ">
                                    <li><strong>Carbon Brush Theory:</strong> <br>Start your journey by understanding the
                                        fundamentals of carbon brushes, their composition, and their role in the operation
                                        of electrical machines.</li>
                                    <li class="mt-2"><strong>Principles of Carbon Brushes:</strong><br> Delve deeper into
                                        the principles that govern the operation of carbon brushes. This module will guide
                                        you through the science behind how carbon brushes work, their physical properties,
                                        and their function in electrical conductivity.</li>
                                    <li class="mt-2"><strong>Maintenance of Carbon Brushes:</strong><br> Learn the critical
                                        aspects of maintaining carbon brushes, including techniques for inspection,
                                        cleaning, and replacement. Understand how proper maintenance can significantly
                                        enhance the performance and lifespan of electrical machines.</li>
                                    <li class="mt-2"><strong>Best Practices:</strong> <br>In the final module, we will share
                                        the industry's best practices in carbon brush maintenance. These tried and tested
                                        practices will help you ensure the efficient and reliable operation of electrical
                                        machines that rely on carbon brushes.</li>
                                </ol>
                            </div>
                    </div>

                </tab>

            </tabs>
        </div>
    </section>

    <FullWidthSlider :images="sliderImages" />
</div>
</template>
  
<script>

import FullWidthSlider from "@/components/FullWidthSlider";
import { ref } from 'vue';
import { Tabs, Tab } from 'flowbite-vue';

import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Training',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
   const activeTab = ref('first');

        return {
            activeTab,
        };

 },
    components: {
     
        FullWidthSlider,
        Tab,
        Tabs,
    },
    
    data() {
        return {
            industries: [
                {
                    title: 'DC Rotating Equipment Maintenance',
                    description: 'Immerse yourself in the world of Direct Current (DC) rotating equipment with our intensive 2-day course. Reid & Mitchell DC Rotating Equipment Maintenance course provides a comprehensive learning experience that brings together the essentials of DC machines, their maintenance, and key operational aspects. This course is designed for both beginners and experienced engineers who wish to deepen their knowledge and skills in the maintenance and operation of DC machines. With a blend of theoretical principles and practical examples, this course will equip you with the knowledge and skills to diagnose, prevent, and solve common problems in DC rotating equipment.',
                    link: '/mining-excavators',
                    icon: 'path-to-icon'
                },
                {
                    title: 'Wheelmotor Maintenance',
                    description: 'This course focuses on the maintenance of off-highway vehicle (OHV) wheelmotors. Trainees will learn about wheelmotor maintenance techniques, including oil analysis, component breakdown, and grease modification. They will also gain insights into problem identification and procedures for taking oil samples. The course emphasizes the importance of proper lubrication and provides key elements for mechanical inspection and recordings.',
                    link: '/mining-excavators',
                    icon: 'path-to-icon'
                },


            ],
            sliderImages: [
                { src: require("@/assets/img//training/trainingwide1.jpg"), alt: "Page 1 Image 1" },
                { src: require("@/assets/img//training/trainingwide2.jpg"), alt: "Page 1 Image 2" },
                { src: require("@/assets/img//training/trainingwide3.jpg"), alt: "Page 1 Image 2" },
                // Add more images for page 1
            ]

        }
    }
}
</script>