<template>
    <!-- Hero Section -->
    <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/marine.jpg')})`}">
        <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
    <h1 class="text-white text-6xl text-center ">MARINE</h1>
  </div>
    </section>

    <section class="container mx-auto text-center p-16 text-lg">
        <p>In order to ensure the smooth operation of electrical rotating equipment in the challenging maritime environment, a specialized team is essential due to the limited access to marine vessels. 
            This team is responsible for meticulously maintaining, repairing, and monitoring the performance parameters of the equipment. Their expertise is crucial to guaranteeing optimal functionality and efficiency in the harsh maritime conditions.</p>
    </section>

  
<!-- Info Section -->
<div class="flex flex-col 2xl:flex-row p-8 px-16">
  <!-- Column 1: Text -->
  <div class="p-4 mb-16 md:mb-0 md:flex-1 items-center justify-center">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">SERVICE
                    <br class="hidden lg:inline-block">OFFERING
                </h1>
                <h3>At Reid & Mitchell, we recognize that every marine application has unique needs. Therefore, we provide customized service solutions tailored to your specific requirements. Our team of experts collaborates closely with you to understand your operational challenges and deliver targeted solutions that address your specific needs.</h3>
                <p class="mb-8 leading-relaxed">
                <ul class="list-disc pl-4">
                    <li> LEAP (Life Expectancy Analysis Program) </li>
                    <li>Service solutions adapted to your needs</li>
                    <li>Full design and manufacturing capability</li>
                    <li>Auxiliary Rotating Equipment</li>
                    <li>On-Site Services</li>
                   
                </ul>
                </p>

  </div>

  <!-- Column 2: Centered Image -->
  <div class="flex items-center justify-center  md:flex-1">
    <ImageSlider :images="page1Images" />
  </div>
</div>

<section>
  <FullWidthSlider :images="WideImages" />
</section>
</template>
<script>
import FullWidthSlider from "@/components/FullWidthSlider";

import ImageSlider from "@/components/ImageSlider.vue";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Marine Industry',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    ImageSlider
  },
  data() {
    return {
  page1Images: [
        { src: require("@/assets/img/marine/marine1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/marine/marine2.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ],
        WideImages: [
        { src: require("@/assets/img/marine/marine1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/marine.jpg"), alt: "Page 1 Image 2" },
        { src: require("@/assets/img/marine/marine2.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ]
}
  }
}
</script>