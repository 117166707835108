<template>
    <div class="full-width-slider">
      <div class="slider-container relative">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.src"
          :alt="image.alt"
          :class="getImageClasses(index)"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      images: {
        type: Array,
        required: true,
      },
      interval: {
        type: Number,
        default: 3000, // 5 seconds interval between slides
      },
    },
    data() {
      return {
        activeIndex: 0,
        timer: null,
      };
    },
    mounted() {
      this.startSlider();
    },
    beforeUnmount() {
      this.stopSlider();
    },
    methods: {
      startSlider() {
        this.timer = setInterval(this.nextImage, this.interval);
      },
      stopSlider() {
        clearInterval(this.timer);
      },
      prevImage() {
        this.activeIndex = (this.activeIndex - 1 + this.images.length) % this.images.length;
      },
      nextImage() {
        this.activeIndex = (this.activeIndex + 1) % this.images.length;
      },
      getImageClasses(index) {
        return {
          'opacity-100': index === this.activeIndex,
          'opacity-0': index !== this.activeIndex,
          'absolute inset-0 transition-opacity duration-500': true,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .full-width-slider {
  width: 100%;
  height: 50vh; /* Set the height of the slider to 100% of the viewport height */
  display: flex;
  align-items: center; /* Vertically center the slider content */
  overflow: hidden;
}

.slider-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.slider-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
}

/* Add your custom styles here */
  </style>
  