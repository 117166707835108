<template>
  <div>
    <!-- Hero Section -->
    <section
      class="bg-cover bg-center screen50"
      :style="{
        backgroundImage: `url(${require('@/assets/img/components/componetshero.jpg')})`,
      }"
    >
      <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
        <h1 class="text-white text-6xl text-center">ROTATING ELECTRICAL COMPONENTS</h1>
      </div>
    </section>

    <!-- About Section -->
    <section class="body-font">
      <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
        <div
          class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"
        >
          <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium">
            ROTATING ELECTRICAL <br class="hidden lg:inline-block" />COMPONENTS
          </h1>
          <p class="mb-8 leading-relaxed text-lg">
            Reid & Mitchell specialize in electrical rotating machinery solutions,
            offering a range of services and products tailored for the mining,
            traction and marine industries. Our expertise includes manufacturing and repairing
            commutators and sliprings with precise engineering, refurbishing control
            equipment for rail and off-highway vehicles, and providing a variety of carbon
            brushes in partnership with Morgan. Emphasizing quality and performance, our
            products and services are designed to meet diverse industry needs, supported
            by technical expertise and a commitment to minimal maintenance and high
            efficiency.
          </p>
        </div>
      </div>
    </section>

    <!-- COURSE Section -->
    <section class="text-center xl:m-10">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium p-16">
        Rotating Electrical Components include:
      </h1>

      <div class="container mx-auto px-4">
        <tabs v-model="activeTab" class="p-5">
          <!-- class appends to content DIV for all tabs -->
          <tab name="first" title="Commutators & Sliprings" class="">
            <div
              class="p-6 mx-auto bg-white shadow-md flex items-center space-x-4 text-start"
            >
              <div class="flex flex-col 2xl:flex-row md:m-24">
                <!-- Column 1: Text -->
                <div class="md:mb-0 md:flex-1 items-center justify-center">
                  <h1 class="title-font sm:text-4xl text-3xl font-medium pb-16">
                    Commutators & Sliprings
                  </h1>
                  <p class="mb-8 leading-relaxed">
                    Reid & Mitchell excel in the repair and manufacturing of various
                    commutators and sliprings for the Electrical Rotating Motor Industry.
                    Our expertise extends to diverse commutator types including
                    V-construction, Outer bound/Banded, and Shrink ring, catering to a
                    range of diameters from 100mm to 3000mm, suitable for all motor types
                    and applications, including High-Speed commutators.
                    <br />
                    <br />
                    We adhere to stringent tolerances, using 1% Silver bearing copper for
                    optimal hardness, and employ Spin Seasoning/Dressing for Traction
                    market commutators, utilizing the latest engineering practices for
                    precision slitting, machining, and finishing. Additionally, we repair
                    and manufacture a range of electrical sliprings, both HV and LV, with
                    sizes ranging from 100mm to 1500mm diameter for various applications
                    like Electrical motors, Cable reels, and Rotating collector rings.
                    <br />
                    <br />
                    Our commitment to quality is evident in our material selection –
                    Zirconium Copper for studs, mild steel, stainless steel, Bronze, and
                    Brass – all subjected to rigorous quality checks and x-ray
                    examinations before final assembly to consistently meet high quality
                    standards.
                  </p>
                </div>

                <!-- Column 2: Centered Image -->
                <div class="flex items-center justify-center md:flex-1">
                  <ImageSlider :images="commutators" />
                </div>
              </div>
            </div>
          </tab>
          <tab name="second" title="Contactors & Control Equipment">
            <div
              class="p-6 mx-auto bg-white shadow-md flex items-center space-x-4 text-start"
            >
              <div class="flex flex-col 2xl:flex-row md:m-24">
                <!-- Column 1: Text -->
                <div class="md:mb-0 md:flex-1 items-center justify-center">
                  <h1 class="title-font sm:text-4xl text-3xl font-medium pb-16">
                    Contactors & Control Equipment
                  </h1>
                  <p class="mb-8 leading-relaxed">
                    Reid and Mitchell specialize in refurbishing control equipment for the
                    rail sector, particularly supporting major rail transportation
                    businesses. We are proficient in refurbishing various components for
                    different classes of locomotives, including 33, 34, 35, and 36 Class
                    locomotives.
                    <br />
                    <br />
                    Our services cover a wide range of parts such as:
                    <br />Dynamic Brake Switches <br />Reverser Switches <br />Contactors
                    <br />Relays <br />Magnet Valves <br />Resistors <br />Exciter
                    Controls <br />Fan Contactors
                    <br />
                    <br />
                    Additionally, we refurbish control equipment and electronic control
                    cards for Off Highway Vehicles, maintaining strong OEM partnerships to
                    ensure repairs and refurbishments meet OEM standards. The range of
                    control equipment refurbished includes Power Control Contactors,
                    Circuit Relays, Reversers, Rectifiers, Exciters, and various static
                    exciters. We also offer OEM repairs for various control cards,
                    ensuring comprehensive refurbishment capabilities in the field.
                  </p>
                </div>

                <!-- Column 2: Centered Image -->
                <div class="flex items-center justify-center md:flex-1">
                  <ImageSlider :images="contactor" />
                </div>
              </div>
            </div>
          </tab>
          <tab name="third" title="Brushes">
            <div
              class="p-6 mx-auto bg-white shadow-md flex items-center space-x-4 text-start"
            >
              <div class="flex flex-col 2xl:flex-row md:m-24">
                <!-- Column 1: Text -->
                <div class="md:mb-0 md:flex-1 items-center justify-center">
                  <h1 class="title-font sm:text-4xl text-3xl font-medium pb-16">
                    Brushes
                  </h1>
                  <p class="mb-8 leading-relaxed">
                    Reid and Mitchell offer a diverse range of carbon brushes, including
                    copper graphite, silver graphite, and others, tailored for optimal
                    performance and minimal maintenance in various applications. Our brushes are designed to suit different brush grade selection for all mining excavators, off-highway vehicles and diesel electric locomotives.
                    Our brushes are backed with technical expertise and onsite support. 
                    <br />
                    <br />
                    This approach enhances motor performance, extends brush life, and
                    reduces maintenance costs. Our partnership with Morgan since 1989
                    provides additional after-sales support and problem-solving for all
                    applications.
                  </p>
                </div>

                <!-- Column 2: Centered Image -->
                <div class="flex items-center justify-center md:flex-1">
                  <ImageSlider :images="brushes" />
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </section>

    <FullWidthSlider :images="sliderImages" />
  </div>
</template>

<script>
import FullWidthSlider from "@/components/FullWidthSlider";
import ImageSlider from "@/components/ImageSlider.vue";

import { ref } from "vue";
import { Tabs, Tab } from "flowbite-vue";

import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Rotating Electical Componets',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })

   const activeTab = ref("first");

    return {
      activeTab,
    };
 },
  components: {
    FullWidthSlider,
    Tab,
    Tabs,
    ImageSlider,
  },
  
  data() {
    return {
      sliderImages: [
        {
          src: require("@/assets/img/components/brushes/brusheswide.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/commutators/commutatorswide1.jpg"),
          alt: "Page 1 Image 1",
        },
        // Add more images for page 1
      ],
      brushes: [
        {
          src: require("@/assets/img/components/brushes/brushes4.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes7.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes3.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes1.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes5.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes6.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/brushes/brushes2.jpg"),
          alt: "Page 1 Image 1",
        },
        // Add more images for page 1
      ],
      commutators: [
        {
          src: require("@/assets/img/components/commutators/commutators2.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/commutators/commutators3.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/commutators/commutators4.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/commutators/commutators5.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/commutators/commutators6.jpg"),
          alt: "Page 1 Image 1",
        },

        // Add more images for page 1
      ],
      contactor: [
        {
          src: require("@/assets/img/components/contactor/contactor1.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor3.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor4.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor1.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor1.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor1.jpg"),
          alt: "Page 1 Image 1",
        },
        {
          src: require("@/assets/img/components/contactor/contactor1.jpg"),
          alt: "Page 1 Image 1",
        },
        // Add more images for page 1
      ],
    };
  },
};
</script>
