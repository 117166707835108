<template>
    <!-- Hero Section -->
    <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/offhighway.png')})`}">
        <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
    <h1 class="text-white text-6xl text-center ">OFF-HIGHWAY VEHICLES</h1>
  </div>
    </section>

    <section class="container mx-auto text-center p-16 text-lg">
        <h2>With a focus on moving ore to tip, Reid & Mitchell keep the wheels turning on electrically-propelled dump trucks.
            Our offerings include
            refurbished and upgraded electrical rotating machines as well as auxiliary controls to suit all our customers’
            needs. We repair mechanical
            components through techniques such as submerged arc micro-welding and precision machining. We are also competent
            in repairing
            electric motors and alternators, and include options to provide specific unit exchange components.</h2>
    </section>

   

<!-- Info Section -->
<div class="flex flex-col 2xl:flex-row p-8 px-16">
  <!-- Column 1: Text -->
  <div class="p-4 mb-16 md:mb-0 md:flex-1 items-center justify-center ">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">SERVICE
                    <br class="hidden lg:inline-block">OFFERING
                </h1>
                <h3>Our service offerings span Wheel Motors (AC and DC), Alternators, and Auxiliary Equipment, all fortified by our dependable On-Site Service and Support. We don't just keep the wheels turning; we ensure that your mining operation remains on the path of unwavering efficiency and productivity.</h3>

                <p class="mb-8 leading-relaxed">
                <ul class="list-disc pl-4">
                    <li>Wheel Motors (AC And DC)</li>
                    <li>Alternators</li>
                    <li>Auxiliary Equipment</li>
                    <li>On-Site Service And Support</li>
                  

                </ul>
                </p>

  </div>

  <!-- Column 2: Centered Image -->
  <div class="flex items-center justify-center  md:flex-1 ">
    <ImageSlider :images="page1Images" />
  </div>
</div>

<section>
  <FullWidthSlider :images="sliderImages" />
</section>
</template>
<script>
import FullWidthSlider from "@/components/FullWidthSlider";
import ImageSlider from "@/components/ImageSlider.vue";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Off-Highway Vehicles',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    ImageSlider
  },
  data() {
    return {
  page1Images: [
        { src: require("@/assets/img/offhighway/offhighway1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway9.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway6.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway7.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighway8.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ],
        sliderImages: [
        { src: require("@/assets/img/offhighway/offhighwaywide1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighwaywide2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighwaywide3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighwaywide4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighwaywide5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/offhighway/offhighwaywide6.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ]
}
  }
}
</script>