<template>
  <!-- Hero Section -->
  <section class="bg-cover bg-center screen50 " :style="{ backgroundImage: `url(${require('@/assets/img/expertise/hero.jpg')})` }">
    <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
      <h1 class="text-white text-6xl text-center ">ENGINEERING EXPERTISE</h1>
    </div>
  </section>
  <section class=" container mx-auto pt-24">
    
    

      <!-- Info Section -->
<div class="flex flex-col xl:flex-row ">
   <!-- Column 2: Centered Image -->
   <div class="flex items-center justify-center  md:flex-1">
    <ImageSlider :images="main" />
  </div>
  <!-- Column 1: Text -->
  <div class="  md:mb-0 md:flex-1 items-center justify-center ">
    
        <p class="mb-8 leading-relaxed text-lg">Since 1935, Reid & Mitchell has gained market recognition within the industry of
          Electrical Rotating Equipment. Initially servicing the Gold mines in the Witwatersrand area, Reid & Mitchells'
          contribution within the mining, traction and OHV market rapidly grew, and now stands proud with over 19000m2 of
          factory engineering production, manufacture and maintenance real estate. These areas of specialised engineering
          capabilities are backed by years of OEM expertise as well as a dedicated, customer focused team of 160 personnel
          members.
        </p>

  </div>

 
</div>
      
    
  </section>

  <section class=" body-font container mx-auto p-4">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">THE REID & MITCHELL
      <br class="hidden lg:inline-block">PROCESS
    </h1>
    <h3 class="py-16">
      In the mining, traction and OHV industry, R&M have been able to push the envelope and develop new products, services
      and methodologies that have advanced them beyond the competition. Through constant adaptability and resilience, R&M
      have been able to sustain innovation through repeatable processes and organizations that ensure advancements happen
      due to planning and not happenstance.
    </h3>

    <ItemsStep v-for="(process, index) in process" :key="index" :title="process.title" :description="process.description"
      :icon="process.icon" />
  </section>

  <!-- Services Section -->
  <section class=" body-font">
    <div class="container px-5 py-24 mx-auto flex flex-wrap ">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">VPI
        <br class="hidden lg:inline-block">PROCESS
      </h1>

      <p class="p-8">
        Vacuum Pressure Impregnation (V.P.I) is a process by which a fully wound electric apparatus stator or rotor is
        completely submerged in a Resin. Through a combination of dry and wet vacuum and pressure cycles, the resin is
        assimilated throughout the insulation system. Once thermally processed, the impregnated windings become a
        monolithic and homogenous structure.
      </p>
      
      <!--Grid-->
      <div class="grid grid-cols-1 xl:grid-cols-2">
        <div class="p-8">
          <ImageSlider :images="vpi" />
        </div>
        <div class="p-8">
          <ItemsStep v-for="(service, index) in services" :key="index" :title="service.title"
            :description="service.description" :icon="service.icon" />
        </div>
        

      </div>
    </div>
  </section>

<section class="container mx-auto">
<!-- Info Section -->
<div class="flex flex-col xl:flex-row  ">
   <!-- Column 2: Centered Image -->
   <div class="flex items-center justify-center  md:flex-1">
    <ImageSlider :images="engineering" />
  </div>
  <!-- Column 1: Text -->
  <div class="p-4 mb-8 md:mb-0 md:flex-1 items-center justify-center xl:pl-24">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">ASSET
          <br class="hidden lg:inline-block">MANAGEMENT
        </h1>
        <p class="mb-8 leading-relaxed">Reid & Mitchell provides a dedicated storage facility exclusively designed for the
          capital equipment owned by our valued customers. Our storage area offers a secure and damage-free environment at
          no cost to our customers. The equipment is housed within a state-of-the-art building equipped with advanced
          security systems, ensuring complete peace of mind.
          <br>
          <br>
          We take responsibility for the efficient management and deployment of the stored capital equipment. Our expert
          team oversees the organization, tracking, and handling of the equipment, ensuring its availability whenever
          required. By effectively managing the stored equipment, we minimize downtime in operations, enabling our
          customers to maintain smooth and uninterrupted business activities. This service provides our customers with the
          assurance that their valuable assets are securely stored and readily accessible, contributing to enhanced
          operational efficiency and peace of mind.
        </p>

  </div>

 
</div>

  
  
</section>
<FullWidthSlider :images="WideImages" />
</template>
    
<script>
import FullWidthSlider from "@/components/FullWidthSlider";

import ItemsStep from '../components/ItemsStep.vue';
import ImageSlider from "@/components/ImageSlider.vue";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Engineering Expertise',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    ItemsStep,
    ImageSlider,
  },
  data() {
    return {

      services: [
        {
          title: 'Pretreatment',
          description: 'The part is preheated in an oven.',
          icon: require('@/assets/img/expertise/Pretreatment.png')
        },
        {
          title: 'Dry Vacuum Step',
          description: 'The part is placed in the pressure vessel, and the cover is closed. The vacuum is pulled to remove air or  until a specified vacuum is achieved. This is the most critical step to ensure the part is fully encapsulated',
          icon: require('@/assets/img/expertise/Dry.png')
        },
        {
          title: 'Wet Vacuum Step',
          description: 'The transfer valve is opened, and the resin or varnish fills the impregnation chamber while under vacuum. ',
          icon: require('@/assets/img/expertise/Wet.png')
        },
        {
          title: 'Pressure',
          description: 'The vacuum is released, and pressure is applied for the resin or varnish to penetrate the voids in the part entirely. ',
          icon: require('@/assets/img/expertise/Pressure.png')
        },
        {
          title: 'Release Pressure',
          description: 'Return unused resin or varnish to the holding tank. ',
          icon: require('@/assets/img/expertise/Release.png')
        },
        {
          title: 'Cure',
          description: 'The part is removed and placed in the customers oven for curing.  ',
          icon: require('@/assets/img/expertise/Cure.png')
        },

      ],
      process: [
        {
          title: 'Define The Problem',
          description: 'We thoroughly examine the issue or challenge at hand. We gather all relevant information, specifications, and requirements to clearly define the problem statement. This allows us to understand the objectives and constraints, ensuring a focused and targeted approach to finding a solution.',
          icon: require('@/assets/img/expertise/Define.png')
        },
        {
          title: 'Clarify the Problem',
          description: 'Once the problem is defined, we proceed with a comprehensive evaluation and testing process. We analyze the available data, conduct tests, and perform assessments to gain a deeper understanding of the problem. This step helps us identify any underlying complexities, potential risks, and specific parameters that need to be considered during the solution development phase.',
          icon: require('@/assets/img/expertise/Clarify.png')
        },
        {
          title: 'Identify the Root Cause of the Problem',
          description: 'Understanding the root cause of the problem is crucial for effective problem-solving. Our team conducts thorough failure analysis to pinpoint the underlying factors contributing to the issue. Through detailed investigations, data analysis, and specialized techniques, we identify the root cause, enabling us to develop targeted solutions that address the fundamental source of the problem.',
          icon: require('@/assets/img/expertise/Identify.png')
        },
        {
          title: 'Develop an Action Plan',
          description: 'With a clear understanding of the problem and its root cause, we develop a comprehensive action plan. The action plan outlines the scope of work, methodologies, and resources required to address the problem effectively. We consider factors such as timelines, budget constraints, and specific client requirements, ensuring that the action plan is tailored to deliver optimal results.',
          icon: require('@/assets/img/expertise/Action.png')
        },
        {
          title: 'Execute The Action Plan',
          description: 'We implement the action plan based on ISO standards, leveraging our extensive experience, and employing tailor-made processes. Our skilled team of engineers and technicians meticulously execute each step of the plan, adhering to quality standards and industry best practices. We employ advanced tools, techniques, and technologies to ensure accuracy, efficiency, and reliability throughout the execution phase.',
          icon: require('@/assets/img/expertise/Execute.png')
        },
        {
          title: 'Evaluate the Results',
          description: 'Once the action plan is executed, we evaluate the results to assess the effectiveness of the implemented solution. We conduct thorough inspections, performance tests, and measurements to gauge the outcomes against the predefined objectives. This evaluation phase helps us verify whether the problem has been resolved and the desired goals have been achieved.',
          icon: require('@/assets/img/expertise/Evaluate.png')
        },
        {
          title: 'Continuously Improve',
          description: 'Continuous improvement is a fundamental aspect of our engineering process. We analyze the results, gather feedback, and identify opportunities for further enhancement. By capturing lessons learned and implementing feedback loops, we continually refine our processes, technologies, and methodologies. This approach ensures that we deliver increasingly efficient, innovative, and reliable solutions to our clients. ',
          icon: require('@/assets/img/expertise/Improve.png')
        },

      ],
      engineering: [
      { src: require("@/assets/img/expertise/asset1.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/asset2.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/asset3.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/asset4.jpg"), alt: "Page 1 Image 1" },


        // Add more images for page 1
      ],
      vpi: [
        { src: require("@/assets/img/expertise/vpi1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/vpi2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/vpi3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/vpi4.jpg"), alt: "Page 1 Image 1" },
        // Add more images for page 1
      ],
      main: [
        { src: require("@/assets/img/expertise/main1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main6.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main7.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main8.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main9.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main10.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main11.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main12.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main13.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/expertise/main14.jpg"), alt: "Page 1 Image 1" },
       
        // Add more images for page 1
      ],
      WideImages: [
      { src: require("@/assets/img/expertise/expwide1.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/expwide2.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/expwide3.jpg"), alt: "Page 1 Image 1" },
      { src: require("@/assets/img/expertise/expwide4.jpg"), alt: "Page 1 Image 1" },
        // Add more images for page 1
      ]
    }
  }
}
</script>