
<template>
    <div class="industry-card">
      <img :src="icon" :alt="title" class="mx-auto h-24 w-24 mb-4">
      <h1 class="mb-2 font-semibold">{{ title }}</h1>
      <p class="mb-4 text-sm">{{ description }}</p>
      <router-link :to="link" class="text-blue-600 hover:text-blue-800 underline text-sm">LEARN MORE</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'IndustryItem',
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .industry-card {
    padding: 2rem;
    text-align: center;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
  }
  </style>
  