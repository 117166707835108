<template>
  <div>
    <!-- Hero Section -->
    <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/onsite/onsitehero.jpg')})`}">
        <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
    <h1 class="text-white text-6xl text-center ">ON-SITE SERVICES</h1>
  </div>
    </section>

    <!-- About Section -->
    

    <!-- Services Section -->
    <section class=" body-font">
      <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">ON-SITE
          <br class="hidden lg:inline-block">SERVICES
        </h1>
       
        <div class="flex flex-wrap w-full">
          <h3 class="py-16 text-lg">
          An Engineering Field Service team of highly
            specialized Service Engineers who assist the customer in diagnosing and
            analysing problems on site.
        </h3>
          <div class="">


            <ItemsStep v-for="(service, index) in services" :key="index" :title="service.title"
              :description="service.description" :icon="service.icon" />




          </div>

         
        </div>
      </div>
      <FullWidthSlider :images="sliderImages" />

    </section>
  </div>
</template>
  
<script>

import ItemsStep from '../components/ItemsStep.vue';
import FullWidthSlider from "@/components/FullWidthSlider";

import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell On-Site Services',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    
    ItemsStep,
    
  },
  data() {
    return {

      services: [
        {
          title: 'Engineering Appraisals of Rotating and Control Equipment',
          description: 'Through thorough inspections, analysis, and testing, we provide you with valuable insights into the health of your rotating and control systems. These appraisals enable you to make informed decisions regarding maintenance, repairs, and upgrades, ensuring the longevity and efficiency of your equipment.',
          icon: require('@/assets/img/onsite/Appraisals.png')
        },
        {
          title: 'Call-back and Troubleshooting Services',
          description: 'Our call-back and troubleshooting services are designed to address any unexpected issues or concerns that may arise with your rotating and control systems. If you encounter a problem, our responsive team is just a call away. We prioritize timely response and dispatch our skilled technicians to your site promptly. They diligently troubleshoot and resolve the issues, minimizing downtime and restoring your equipment to optimal functioning.',
          icon: require('@/assets/img/onsite/Troubleshooting.png')
        },
        {
          title: 'Training on Rotating and Control Systems',
          description: 'We understand the importance of equipping our customers with the knowledge and skills to effectively operate and maintain their rotating and control systems. Our training programs are designed to provide comprehensive knowledge and practical expertise in handling and maintaining your equipment. Whether its understanding system operations, troubleshooting techniques, or preventive maintenance, our training sessions empower your team to optimize the performance and reliability of your rotating and control systems.',
          icon: require('@/assets/img/onsite/Training.png')
        },
        {
          title: 'On-site Machining',
          description: 'With our state-of-the-art portable machining equipment and skilled technicians, we can perform precise machining operations on your rotating equipment, such as flange facing, line boring, or shaft alignment, without the need for equipment disassembly. This minimizes downtime, enhances equipment integrity, and ensures accurate alignment and performance.',
          icon: require('@/assets/img/onsite/Machining.png')
        },
        {
          title: 'Infra-red Scanning',
          description: 'We utilize advanced infra-red scanning technology to detect and analyze potential issues in your rotating and control systems. Our highly trained technicians employ thermal imaging techniques to identify anomalies such as overheating, electrical faults, or insulation problems. By detecting these issues early on, we can take proactive measures to prevent equipment failures, optimize energy efficiency, and enhance safety.',
          icon: require('@/assets/img/onsite/Scanning.png')
        },
        {
          title: 'Re-alignment of Rotating Equipment',
          description: 'Proper alignment is crucial for the optimal performance and longevity of rotating equipment. Our skilled technicians specialize in re-aligning rotating equipment to precise specifications. Using advanced alignment techniques and equipment, we ensure that your machinery operates smoothly, reducing vibration, wear, and energy consumption. This improves overall equipment reliability, minimizes maintenance requirements, and extends the lifespan of your rotating systems.',
          icon: require('@/assets/img/onsite/Realignment.png')
        },
        {
          title: 'Maintenance Service Level Agreements ',
          description: 'We offer customizable maintenance service level agreements tailored to your specific requirements. These agreements provide a structured framework for ongoing maintenance activities, ensuring regular inspections, preventive maintenance, and timely interventions. With a maintenance service level agreement in place, you benefit from a proactive and systematic approach to equipment maintenance, enhancing reliability, minimizing downtime, and optimizing the lifespan of your rotating and control systems.',
          icon: require('@/assets/img/onsite/Maintenance.png')
        },
      ],
      
        sliderImages: [
        { src: require("@/assets/img/onsite/onsite1.jpg"), alt: "Page 1 Image 1" },
       
        { src: require("@/assets/img/onsite/onsite3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/onsite/onsite4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/onsite/onsite5.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ]
    }
  }
}
</script>