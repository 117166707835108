<template>
    <div class="flex relative pb-12">
      <div class="h-full w-16 absolute inset-0 flex items-center justify-center">
        <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div class="flex-shrink-0 w-16 h-16  inline-flex items-center justify-center text-white relative z-10">
        <img :src="icon" :alt="title" class="mx-auto h-16 w-16 mb-4">
      </div>
      <div class="flex-grow pl-4">
        <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">{{ title }}</h2>
        <p class="leading-relaxed">{{ description }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ItemsStep',
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      }
    }
  }
  </script>
  