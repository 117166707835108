<template>
  <div id="app">
   
    <NavHeader />
    
    <router-view />
    <FooterNav />
  </div>
</template>

<script>


import NavHeader from './components/NavHeader.vue'

import FooterNav from './components/FooterNav.vue'
import { useHead } from '@unhead/vue'
export default {
  name: 'App',
  components: {
   
    NavHeader,
   
    FooterNav
  },
  setup() {
   
    useHead({
      title: 'Reid & Mitchell',
      meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
    })
  },
}

</script>

<style>
/* Add any global styles here */
body, html {
  height: 100%;
  margin: 0;
}

#app {
  position: relative;
  min-height: 100%;
  min-width: 100%;
}

#app::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('@/assets/drawing.svg') no-repeat center center; /* Ensure the path is correct */
  background-size: cover;
  opacity: 0.02; /* Adjust the opacity as needed */
  z-index: -1;
}

/* Ensure the content sits above the pseudo-element */
.NavHeader, .router-view, .FooterNav {
  position: relative;
  z-index: 1;
}
</style>
