<template>
    <div>
        <!-- Hero Section -->
        <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/about/abouthero.jpg')})`}">
            <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
    <h1 class="text-white text-6xl text-center ">ABOUT US</h1>
  </div>
        </section>
<!-- Info Section -->
<div class="flex flex-col 2xl:flex-row p-8 md:m-24">
   
  <!-- Column 1: Text -->
  <div class="p-4 mb-16 md:mb-0 md:flex-1 items-center justify-center ">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">ABOUT
                        <br class="hidden lg:inline-block">REID & MITCHELL
                    </h1>
                    <p class="mb-8 leading-relaxed">With a rich history dating back to 1935, Reid and Mitchell have established an impressive reputation in the maintenance, service, repair, refurbishment, and manufacturing of high-value electrical rotating equipment. In Southern Africa, we play a crucial role as a trusted supplier to Off-Highway Vehicle (OHV) OEMs, coal mining companies operating draglines, and the diesel electric rail transport market.
                        <br>
                        <br>
                        Recognizing the unique challenges posed by the maritime environment, we understand the importance of having a specialized team due to the limited access to marine vessels. At Reid & Mitchell, we are dedicated to delivering world-class solutions that ensure the smooth operation of electrical rotating equipment. Our commitment lies in providing timeless reliability and exceptional quality. 
                        <br>
                        <br>
                        As part of our comprehensive after-market support, we take pride in offering outstanding on-site maintenance services delivered by our team of specialist service engineers. With Reid & Mitchell, you can rely on our expertise and unwavering commitment to excellence.
                    </p>
  </div>

   <!-- Column 2: Centered Image -->
   <div class="flex items-center justify-center  md:flex-1">
    <ImageSlider :images="page1Images" />
  </div>
</div>
       
        <!--division of Actom-->
        <section class="container mx-auto p-8">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">A Division of <span class="text-brand-blue">ACTOM</span>
                    </h1>
                    <p class="mb-8 leading-relaxed">Reid & Mitchell stands as a division of ACTOM, a leading electrical engineering group with a global reach. This affiliation with ACTOM brings numerous benefits to Reid & Mitchell and enhances its capabilities. With access to ACTOM's extensive network and resources, Reid & Mitchell gains the advantage of a wider market presence and enhanced business opportunities on a global scale. This global reach allows Reid & Mitchell to tap into new markets, collaborate with international partners, and leverage ACTOM's expertise and industry connections. By being part of the ACTOM family, Reid & Mitchell is well-positioned to deliver innovative solutions, expand its reach, and provide exceptional value to its clients worldwide.</p>
        </section>

        <section class="text-white body-font bg-brand-blue">
            <div class="container px-5 py-24 mx-auto">
                <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                        class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                        <path
                            d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                        </path>
                    </svg>
                    <h1 class="text-lg title-font tracking-wider p-8 ">REID & MITCHELL STRIVES TO
                        PROVIDE:</h1>
                    <p class="leading-relaxed text-lg">“Total Capability Engineering and repair Services to the
                        Mining, Metal, Traction and General Industries. This
                        included Rotating Equipment Repair and Rebuild
                        capability, combined with on-site Services that ranged
                        from Rotating Equipment Services to Regulator Tune-ups
                        and commissioning from start-up”.</p>
                  

                  
                </div>
            </div>
        </section>

    </div>
</template>
  
<script>
import ImageSlider from "@/components/ImageSlider.vue";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell About Us',
     meta: [
        {
          name: `Learn about Reid & Mitchell's history, commitment to quality, and our dedicated team. Explore our journey in providing electrical rotating machinery solutions.`,
        
          keywords: 'Reid & Mitchell history, electrical machinery, team, quality commitment',
        },
        ],
   })
 },
  components: {
    
    ImageSlider
  },
  data() {
    return {
  page1Images: [
        { src: require("@/assets/img/about/about1.jpg"), alt: "Page 1 Image 1" },
       
          // Add more images for page 1
        ]
}
  }
}
</script>