<template>
  <!-- Hero Section -->
  <section class="bg-cover bg-center screen50" :style="{ backgroundImage: `url(${require('@/assets/img/dragline.jpg')})` }">
    <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
      <h1 class="text-white text-6xl text-center">MINING EXCAVATORS</h1>
    </div>
  </section>
  <section class="container mx-auto text-center p-8 text-lg">
        <h2>Keeping the excavation machinery going is the heart of any opencast mining operation. Reid & Mitchell offers a range of
refurbished, upgraded, and new Electrical Rotating Machinery to its valued customers, to ensure continuous production. We
specialise in DC generators and motors, including in-house commutator manufacturing for up to 1300 Hp DC plus motors and
generators. We have recently included a new axial fan which allows for improved motor cooling and efficiency. We also design
and manufacture the conversions of 60Hz MG sets to operate at 50Hz at the same power levels.</h2>
    </section>
 
<!-- Info Section -->
  <div class="flex flex-col 2xl:flex-row p-8 px-16">
  <!-- Column 1: Text -->
  <div class="p-4 mb-16 md:mb-0 md:flex-1 items-center justify-center ">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">SERVICE
          <br class="hidden lg:inline-block">OFFERING
        </h1>
        <h3>Our service offerings extend beyond the products themselves. From MD800 Series Motors to MCF Generators, Synchronous Motors, Cooling Fans, Auxiliary Rotating Equipment, and White Metal Bearings, our holistic approach guarantees comprehensive support. We're your partners not only in machinery but also in maximizing your mining productivity, supported by our dependable On-Site Services.</h3>

        <p class="mb-8 leading-relaxed">
        <ul class="list-disc pl-4">
          <li>MD800 Series Motors</li>
          <li>MCF Generators</li>
          <li>Synchronous Motors</li>
          <li>Cooling Fans</li>
          <li>Auxiliary Rotating Equipment</li>
          <li>White Metal Bearings</li>
          <li>On-Site Services</li>

        </ul>
        </p>
  </div>

  <!-- Column 2: Centered Image -->
  <div class="flex items-center justify-center  md:flex-1 ">
    <ImageSlider :images="page1Images" />
  </div>
</div>

  <section>
   
    <FullWidthSlider :images="WideImages" />

  </section>
</template>
<script>

import ImageSlider from "@/components/ImageSlider.vue";
import FullWidthSlider from "@/components/FullWidthSlider";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Mining Industry',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    ImageSlider
  },
  data() {
    return {
      page1Images: [
        { src: require("@/assets/img/mining/mine1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/mine2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/mine3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/mine4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/mine5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/mine6.jpg"), alt: "Page 1 Image 1" },
        // Add more images for page 1
      ],
      WideImages: [
        { src: require("@/assets/img/mining/minewide1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide6.jpg"), alt: "Page 1 Image 1" },   
        { src: require("@/assets/img/mining/minewide7.jpg"), alt: "Page 1 Image 1" },   
        { src: require("@/assets/img/mining/minewide8.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/mining/minewide9.jpg"), alt: "Page 1 Image 1" },              // Add more images for page 1
      ]
    }
  }
}
</script>