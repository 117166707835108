<template>
 
    <div class="image-slider">
      <div class="slider-container relative">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image.src"
          :alt="image.alt"
          :class="getImageClasses(index)"
        />
      </div>
    </div>
 
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    interval: {
      type: Number,
      default: 4000, // 5 seconds interval between slides
    },
  },
  data() {
    return {
      activeIndex: 0,
      timer: null,
    };
  },
  mounted() {
    this.startSlider();
  },
  beforeUnmount() {
    this.stopSlider();
  },
  methods: {
    startSlider() {
      this.timer = setInterval(this.nextImage, this.interval);
    },
    stopSlider() {
      clearInterval(this.timer);
    },
    prevImage() {
      this.activeIndex = (this.activeIndex - 1 + this.images.length) % this.images.length;
    },
    nextImage() {
      this.activeIndex = (this.activeIndex + 1) % this.images.length;
    },
    getImageClasses(index) {
      return {
        'opacity-100': index === this.activeIndex,
        'opacity-0': index !== this.activeIndex,
        'absolute inset-0 transition-opacity duration-500': true,
      };
    },
  },
};
</script>

<style scoped>
.image-slider {
  height: 700px; /* Set the desired height of the slider */
  width: 100%;
  
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;  /* or any other width you prefer */
  margin: 0 auto; /* this does the same thing as the Tailwind mx-auto class */
  position: relative;
  overflow: hidden;
 
}

.slider-container img {
  max-height: 100%;
  object-fit: contain; /* Confine the image within the container */
}

/* Responsive Styles */
@media (max-width: 767px) {
  .image-slider {
    height: auto; /* Adjust the height to fit content */
    
  }

  .slider-container {
    height: 0;
    padding-bottom: 100%; /* Reset the aspect ratio */
  }
}



</style>
