<template>
 <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    <div class="grid grid-cols-2 gap-4">
        <!-- Column 1: Contact Details -->
        <div class="p-6 bg-white rounded-xl shadow-md space-y-4">
            <h2 class="text-xl font-semibold ">Contact Details</h2>
            <p class="text-gray-500">
                <strong>Address:</strong> <br>
                24 van Dyk Road <br>
                Benoni, Gauteng <br>
                South Africa <br>
                <strong>Phone:</strong> 011 914 9600 <br>
                <strong>Email:</strong> <br>
                <a href="mailto:enquiries@reidmitchell.co.za ">enquiries@reidmitchell.co.za  </a> <br>
                <a href="mailto:orders@reidmitchell.co.za">orders@reidmitchell.co.za</a>
            </p>
        </div>
        <!-- Column 2: Contact Form -->
        <div class="p-6 bg-white rounded-xl shadow-md space-y-4">
            <h2 class="text-xl font-semibold ">Contact Form</h2>
            <form class="" method="POST" action="api\contact_handler.php">
                <input class="form-input mt-1 block w-full" type="text" placeholder="Name" name ="name" required>
                <input class="form-input mt-1 block w-full" type="text" placeholder="Surname" name ="surname" required>
                <input class="form-input mt-1 block w-full" type="tel" placeholder="Phone Number" name ="phone" required>
                <input class="form-input mt-1 block w-full" type="email" placeholder="Email" name ="email" required>
                <select class="form-select mt-1 block w-full" name = "inquiry">
                    <option value = "HR">HR</option>
                    <option value = "Sales">Sales</option>
                    <option value = "Technical">Technical</option>
                </select>
                <textarea class="form-textarea mt-1 block w-full" rows="3" placeholder="Enter your message here" name = "message"></textarea>
                <div style="display:none;">
                <input type="text" name="botspot" id="botspot" value="" />
              </div>
                <button class="bg-brand-red hover:bg-brand-blue text-white font-bold py-2 px-4 rounded mt-4" type="submit">
                    Submit
                </button>
            </form>
        </div>
    </div>
    <!-- Full width map -->
    <div class="mt-8">
        <iframe src="https://maps.google.com/maps?q=24%20van%20Dyk%20Road%20Benoni,%20Gauteng%20South%20Africa&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="450" style="border:0;" allowfullscreen=""></iframe>
    </div>
</div>

</template>

<script>
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Contact Us',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
