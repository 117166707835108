<template>
  
    <!-- Hero Section -->
    <section class="bg-cover bg-center screen50 "
      :style="{ backgroundImage: `url(${require('@/assets/img/home.png')})` }">
      <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
        <h1 class="text-white text-6xl text-center ">YOUR EQUIPMENT
          <br>
          LIFE PARTNER
        </h1>
      </div>
    </section>

    <!-- Industries Section -->
    <section class=" lg:p-24">
      <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 bg-white">
        <IndustryItem v-for="(industry, index) in industries" :key="index" :title="industry.title"
          :description="industry.description" :link="industry.link" :icon="industry.icon" />
      </div>
    </section>


    <!-- Services Section -->
    <section class="text-gray-600 body-font">
      <div class="container px-5  mx-auto flex flex-wrap ">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium p-8 text-gray-900">OUR
          <br class="hidden lg:inline-block">EXPERTISE
        </h1>
        
        <div class="grid grid-cols-1 xl:grid-cols-2">
        <div>
          <ItemsStep v-for="(service, index) in services" :key="index" :title="service.title"
            :description="service.description" :icon="service.icon" />
        </div>
        <div>
          <ImageSlider :images="HomeImages" />
        </div>

      </div>
      </div>
    </section>

    
  
</template>

<script>
import IndustryItem from '../components/IndustryItem.vue'
import ItemsStep from '../components/ItemsStep.vue';
import ImageSlider from "@/components/ImageSlider.vue";
import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Home',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },

  components: {
    IndustryItem,
    ItemsStep,
    ImageSlider,
  },
  
  data() {
    return {
      industries: [
        {
          title: 'MINING EXCAVATORS',
          description: 'Keeping the excavation machinery going is the heart of any opencast mining operation.',
          link: '/mining',
          icon: require('@/assets/img/dragline.png')
        },
        {
          title: 'OFF-HIGHWAY VEHICLES ',
          description: 'With a focus on moving ore to tip, Reid & Mitchell keep the wheels turning on electrically-propelled dump trucks.',
          link: '/offhighway',
          icon: require('@/assets/img/offhighway.svg')
        },
        {
          title: 'TRACTION',
          description: 'Diesel electric locomotives move passengers and goods safely and efficiently by generating electricity to power traction motors.',
          link: '/traction',
          icon: require('@/assets/img/traction.svg')
        },
        {
          title: 'MARINE',
          description: 'In order to ensure the smooth operation of electrical rotating equipment in the challenging maritime environment, a specialized team is essential due to the limited access to marine vessels.',
          link: '/marine',
          icon: require('@/assets/img/marine.svg')
        },
      ],
      services: [
        {
          title: 'Turnkey AC & DC Rotating Equipment Solutions',
          description: 'Comprehensive solutions for AC (Alternating Current) and DC (Direct Current) rotating equipment, including design, installation, maintenance, and troubleshooting.',
          icon: require('@/assets/img/home/Turnkey.png')
        },
        {
          title: 'Fault Detection & Diagnostic of Rotating Equipment',
          description: 'Advanced techniques and technologies to identify and diagnose faults or issues in rotating equipment, to ensure optimal performance and prevent failures.',
          icon: require('@/assets/img/home/Fault.png')
        },
        {
          title: '24/7 Onsite Engineering Services',
          description: 'Engineering services that are available round the clock and provided directly at the clients location. They encompass a wide range of technical support, troubleshooting, maintenance, and repair activities.',
          icon: require('@/assets/img/home/Onsite.png')
        },
        {
          title: 'Leading Engineering Processes & Best Practices',
          description: 'Adoption and implementation of industry-leading engineering processes and best practices to enhance efficiency, productivity, quality, and safety in engineering operations.',
          icon: require('@/assets/img/home/Process.png')
        },
        {
          title: 'Skills Development & Training',
          description: 'Programs and initiatives to develop the skills and knowledge of individuals in the field of engineering. It includes training sessions, workshops, courses, and mentoring to enhance technical expertise and professional growth.',
          icon: require('@/assets/img/home/Training.png')
        },

      ],
      HomeImages: [
        { src: require("@/assets/img/home/home1.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home2.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home3.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home4.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home5.jpg"), alt: "VPI Plant" },
  
        { src: require("@/assets/img/home/home7.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home8.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home9.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home10.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home11.jpg"), alt: "VPI Plant" },
        { src: require("@/assets/img/home/home12.jpg"), alt: "VPI Plant" },
       
        { src: require("@/assets/img/home/home14.jpg"), alt: "VPI Plant" },
        // Add more images for page 1
      ]
    }
  }
}
</script>