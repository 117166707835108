<template>
    <!-- Hero Section -->
    <section class="bg-cover bg-center screen50 " :style="{backgroundImage: `url(${require('@/assets/img/traction.png')})`}">
        <div class="h-full bg-black bg-opacity-10 flex items-center justify-center">
    <h1 class="text-white text-6xl text-center ">TRACTION</h1>
  </div>
    </section>

    <section class="container mx-auto text-center p-16 text-lg">
        <p>Diesel electric locomotives move passengers and goods safely and efficiently by
            generating electricity to power traction motors. Reid & Mitchell manufactures,
            refurbishes, and upgrades a range of electrical rotating machines and auxiliary
            controls for these prime movers of our economy. Recent developments include a motor
            suspension modification for 761 type traction motors in both cape gauge and standard
            gauge specifications.</p>
    </section>



    <!-- Info Section -->
<div class="flex flex-col 2xl:flex-row p-8 px-16">
  <!-- Column 1: Text -->
  <div class="p-4 mb-16 md:mb-0 md:flex-1 items-center justify-center">
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">SERVICE
                    <br class="hidden lg:inline-block">OFFERING
                </h1>
                <h3>In line with our unwavering pursuit of comprehensive service provision, our offerings encompass Generators, Alternators, Traction Motors, Auxiliary Rotating Equipment, and Auxiliary Electrical Equipment. These components collectively epitomize the critical backbone of locomotive operation, ensuring the reliability and efficiency that define the modern railway landscape. Our holistic approach extends further with On-Site Service and Support, a testament to our commitment to partner with clients in their journey to operational excellence. As locomotives traverse vast distances, we're committed to ensuring that each journey is defined by the highest standards of performance, safety, and sustainability.</h3>

                <p class="mb-8 leading-relaxed">
                <ul class="list-disc pl-4">
                    <li>Generators</li>
                    <li>Alternators</li>
                    <li>Traction Motors</li>
                    <li>Auxiliary Rotating Equipment</li>
                    <li>Auxiliary Electrical Equipment</li>
                    <li>On-Site Service And Support</li>
                  

                </ul>
                </p>

  </div>

  <!-- Column 2: Centered Image -->
  <div class="flex items-center justify-center  md:flex-1">
    <ImageSlider :images="page1Images" />
  </div>
</div>

    <section>
      <FullWidthSlider :images="sliderImages" />
    </section>
</template>
<script>
import ImageSlider from "@/components/ImageSlider.vue";
import FullWidthSlider from "@/components/FullWidthSlider";

import { useHead } from '@unhead/vue'
export default {
  setup() {
   
   useHead({
     title: 'Reid & Mitchell Traction Industry',
     meta: [
        {
          name: `Discover our range of electrical rotating machinery solutions at Reid & Mitchell. We specialize in providing top-notch services for mining, marine, off-highway, traction, and more.`,
        
          keywords: 'electrical rotating machinery, mining, marine, off-highway, traction',
        },
        ],
   })
 },
  components: {
    FullWidthSlider,
    ImageSlider
  },
  data() {
    return {
  page1Images: [
        { src: require("@/assets/img/traction/traction1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction6.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction7.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction8.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/traction10.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ],
        sliderImages: [
        { src: require("@/assets/img/traction/tractionwide1.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide2.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide3.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide4.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide5.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide6.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide7.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide8.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide9.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide10.jpg"), alt: "Page 1 Image 1" },
        { src: require("@/assets/img/traction/tractionwide11.jpg"), alt: "Page 1 Image 1" },
          // Add more images for page 1
        ]
}
  }
}
</script>