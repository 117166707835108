import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/HomeView.vue'
import About from './views/AboutView.vue'
import Mining from './views/MiningView.vue'
import Marine from './views/MarineView.vue'
import Offhighway from './views/OffhighwayView.vue'
import Traction from './views/TractionView.vue'
import OnSite from './views/OnSiteView.vue'
import Expertise from './views/ExpertiseView.vue'
import Training from './views/TrainingView.vue'
import Quality from './views/QualityView.vue'
import Contact from './views/ContactView.vue'
import ThankYou from './views/ThankyouView.vue'
import Component from './views/ComponentView.vue'
const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/mining', component: Mining },
  { path: '/offhighway', component: Offhighway },
  { path: '/traction', component: Traction },
  { path: '/marine', component: Marine },
  { path: '/onsite', component: OnSite },
  { path: '/expertise', component: Expertise },
  { path: '/training', component: Training },
  { path: '/quality', component: Quality },
  { path: '/contact-us', component: Contact },
  { path: '/thank-you', component: ThankYou },
  { path: '/component', component: Component },
]

const router = createRouter({
  history: createWebHistory(),
  routes, 
})

export default router
