<template>
    <header class="p-4 bg-white text-dark shadow-md">
      <nav class="container mx-auto flex items-center justify-between ">
        <div>
          <!-- Logo -->
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/')"><img src="@/assets/img/ReidMitchellLogo.png" alt="Logo" class="w-auto h-24 p-5" /></a>
          
      
        </div>
        <!-- Hamburger Button (Visible on small screens) -->
        <button class="lg:hidden flex items-center" @click="showMobileMenu = !showMobileMenu">
          MENU
        </button>
        <!-- Desktop Menu (Hidden on small screens) -->
        <div class="hidden lg:flex space-x-4">
          <!-- Other Links -->
        
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/about')">ABOUT US</a>
  
          <!-- Dropdown Menu -->
          <div class="relative">
            <button class="hover:text-gray-300" @click="showDropdown = !showDropdown">INDUSTRIES</button>
            <div v-if="showDropdown" class="absolute top-full mt-2 space-y-2 bg-brand-red text-white p-4 rounded shadow">
              <a class="hover:text-gray-300 block cursor-pointer" @click="navigateTo('/mining')">MINING EXCAVATORS</a>
              <a class="hover:text-gray-300 block cursor-pointer" @click="navigateTo('/offhighway')">OFF-HIGHWAY VEHICLES</a>
              <a class="hover:text-gray-300 block cursor-pointer"  @click="navigateTo('/traction')">TRACTION</a>
              <a class="hover:text-gray-300 block cursor-pointer" @click="navigateTo('/marine')">MARINE</a>
            </div>
          </div>
  
          <!-- More Links -->
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/component')">COMPONENTS</a>
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/onsite')">ON-SITE SERVICES</a>
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/expertise')">ENGINEERING EXPERTISE</a>
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/training')">TRAINING</a>
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/quality')">QUALITY & ACCREDITATIONS</a>
          <a class="hover:text-gray-300 cursor-pointer" @click="navigateTo('/contact-us')">CONTACT US</a>
        </div>
        <!-- Mobile Menu (Visible on small screens) -->
        <div v-if="showMobileMenu" class="fixed inset-0 bg-brand-red bg-opacity-50 z-50 lg:hidden">
          <div class="bg-gray-800 text-white w-64 h-full p-8">
            <!-- Menu Items -->
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/')">HOME</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/about')">ABOUT US</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/mining')">MINING EXCAVATORS</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/offhighway')">OFF-HIGHWAY VEHICLES</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/traction')">TRACTION</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/marine')">MARINE</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/component')">COMPONENTS</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/onsite')">ON-SITE SERVICES</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/expertise')">ENGINEERING EXPERTISE</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/training')">TRAINING</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/quality')">QUALITY & ACCREDITATIONS</a>
            <a class="hover:text-gray-300 block m-2" @click="navigateTo('/contact-us')">CONTACT US</a>
          </div>
        </div>
      </nav>
    </header>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  
  export default {
    setup() {
      const route = useRoute()
      const router = useRouter()
      const showDropdown = ref(false)
      const showMobileMenu = ref(false) // New
  
      const navigateTo = (path) => {
        router.push(path)
        showDropdown.value = false
        showMobileMenu.value = false // New
      }
  
      return {
        route,
        showDropdown,
        showMobileMenu, // New
        navigateTo,
      }
    },
  }
  </script>
  
  <style scoped>
  /* Your Tailwind CSS will go here */
  </style>
  